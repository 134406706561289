<template>
  <div class="absolute bottom-5 top-5 left-10 ml-12 z-30 bg-white shadow grid grid-cols-2 pr-4 pl-6 pt-5 w-module mailing rounded-xl" style="grid-template-rows: auto 1fr">
    <div class="text-darkblue col-start-1 col-end-2 text-xl font-bold whitespace-no-wrap font-SourceSansPro">{{ title === 1 ? $t('mailing.create') : $t('mailing.edit') }}</div>
    <div class="flex justify-end col-start-2 items-center">
      <img v-if="notRole && title !== 1" class="mr-5" src="../../assets/icons/history.svg" style="width: 18px" @click="$emit('show-history', { objects: 'mailings', objectId: mailing.id })" />
      <i class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold" @click="$emit('close')"></i>
    </div>
    <div class="grid col-start-1 col-end-4 overflow-y-auto relative" style="grid-template-rows: repeat(9, auto) 1fr">
      <div class="flex mt-4 relative">
        <el-input v-model="mailing.name" :class="{ 'input-error': isNameMissing }" :placeholder="$t('mailing.mailing')" class="w-2/3" type="text"></el-input>
        <skif-button v-if="!admin" class="ml-6" width="24" @click="createOrUpdate">{{ title === 1 ? $t('create') : $t('change') }}</skif-button>
        <transition name="el-zoom-in-top">
          <div v-if="isNameMissing" class="text-notify text-xs top-full left-0 absolute">{{ $t('mailing.enterName') }}</div>
        </transition>
      </div>
      <div class="flex flex-col mt-4"><span class="text-annotationColor font-SourceSansPro text-sm font-semibold">{{ $t('mailing.template') }}</span>
        <el-select v-if="!admin" v-model="reportTemplateId" :class="'w-2/3'" class="mt-4" filterable="filterable" type="text">
          <el-option v-for="item in templates" :key="item.id" :label="item.name" :value="item.id" class="text-ellipsis" style="max-width: 272px"></el-option>
        </el-select>
        <el-select v-else="v-else" v-model="mailing.report_template.name" :class="'w-2/3'" class="mt-4" disabled="disabled" filterable="filterable" type="text">
          <el-option v-for="item in templates" :key="item.id" :label="item.name" :value="item.id" class="text-ellipsis" style="max-width: 272px"></el-option>
        </el-select>
      </div>
      <div class="flex mt-4 relative"><span class="text-annotationColor font-SourceSansPro text-sm font-semibold">{{ $t('mailing.vehicle') }}</span>
        <div v-if="isOnlyUnits" class="flex cursor-pointer fixed" style="left: 240px">
          <svg class="ml-4" fill="none" height="20" viewBox="0 0 18 18" width="20" xmlns="http://www.w3.org/2000/svg" @click.stop="isShowAllUnits = !isShowAllUnits">
            <path :fill="!showAllUnits ? '#F56C6C' : '#5477A9'" clip-rule="evenodd" d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" fill-rule="evenodd"></path>
            <path :fill="!showAllUnits ? '#F56C6C' : '#5477A9'" clip-rule="evenodd"
                  d="M13.3446 7.18139C13.3196 7.18139 13.2957 7.17718 13.2715 7.17467L4.64671 7.18287V7.18036C4.28874 7.17592 4 6.91396 4 6.59065C4 6.26437 4.29341 6 4.65558 6C4.66227 6 4.66846 6.00148 4.67516 6.00182H13.3248C13.3315 6.00171 13.3377 6 13.3444 6C13.7066 6 14 6.26435 14 6.59065C14.0001 6.91692 13.7067 7.18139 13.3446 7.18139ZM6.17718 8.74201C6.18387 8.74201 6.19007 8.74349 6.19676 8.74383H11.8031C11.8098 8.74349 11.816 8.74201 11.8227 8.74201C12.1848 8.74201 12.4783 9.00636 12.4783 9.33265C12.4783 9.65893 12.1848 9.9233 11.8227 9.9233C11.7977 9.9233 11.7738 9.9192 11.7495 9.91659L6.16809 9.9249V9.92239C5.81013 9.91795 5.52138 9.65599 5.52138 9.33256C5.52138 9.00649 5.81526 8.74201 6.17718 8.74201ZM7.9163 11.4839C7.923 11.4839 7.92919 11.4855 7.93589 11.4857H10.0642C10.0709 11.4856 10.0771 11.4839 10.0838 11.4839C10.4459 11.4839 10.7394 11.7485 10.7394 12.0745C10.7394 12.4008 10.4459 12.6652 10.0838 12.6652C10.0588 12.6652 10.0349 12.661 10.0106 12.6585L7.90744 12.6667V12.6642C7.54947 12.6597 7.26073 12.3978 7.26073 12.0743C7.26073 11.7485 7.55426 11.4839 7.9163 11.4839Z"
                  fill-rule="evenodd"></path>
          </svg>
          <transition mode="out-in" name="slide-fade">
            <div v-if="isShowAllUnits" class="absolute bg-white px-2 py-4 pl-3" style="left: 20px; border: 1px solid #cfdbeb; bottom: 25px; width: 320px; border-radius: 5px">
              <div class="text-darkblue font-SourceSansPro text-sm font-semibold">
                <div class="flex mb-2">
                  <svg class="mt-2 mr-4" fill="none" height="20" viewBox="0 0 18 18" width="20" xmlns="http://www.w3.org/2000/svg">
                    <path clip-rule="evenodd" d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" fill="#5477A9" fill-rule="evenodd"></path>
                    <path clip-rule="evenodd"
                          d="M13.3446 7.18139C13.3196 7.18139 13.2957 7.17718 13.2715 7.17467L4.64671 7.18287V7.18036C4.28874 7.17592 4 6.91396 4 6.59065C4 6.26437 4.29341 6 4.65558 6C4.66227 6 4.66846 6.00148 4.67516 6.00182H13.3248C13.3315 6.00171 13.3377 6 13.3444 6C13.7066 6 14 6.26435 14 6.59065C14.0001 6.91692 13.7067 7.18139 13.3446 7.18139ZM6.17718 8.74201C6.18387 8.74201 6.19007 8.74349 6.19676 8.74383H11.8031C11.8098 8.74349 11.816 8.74201 11.8227 8.74201C12.1848 8.74201 12.4783 9.00636 12.4783 9.33265C12.4783 9.65893 12.1848 9.9233 11.8227 9.9233C11.7977 9.9233 11.7738 9.9192 11.7495 9.91659L6.16809 9.9249V9.92239C5.81013 9.91795 5.52138 9.65599 5.52138 9.33256C5.52138 9.00649 5.81526 8.74201 6.17718 8.74201ZM7.9163 11.4839C7.923 11.4839 7.92919 11.4855 7.93589 11.4857H10.0642C10.0709 11.4856 10.0771 11.4839 10.0838 11.4839C10.4459 11.4839 10.7394 11.7485 10.7394 12.0745C10.7394 12.4008 10.4459 12.6652 10.0838 12.6652C10.0588 12.6652 10.0349 12.661 10.0106 12.6585L7.90744 12.6667V12.6642C7.54947 12.6597 7.26073 12.3978 7.26073 12.0743C7.26073 11.7485 7.55426 11.4839 7.9163 11.4839Z"
                          fill="#5477A9"
                          fill-rule="evenodd"></path>
                  </svg>
                  <div class="flex flex-col"><span>Применена фильтрация из Мониторинга.</span><span>Отображаются не все объекты.</span></div>
                </div>
                <div class="flex items-center">
                  <skif-checkbox v-model="showAllUnits" class="mr-4 ml-1" @change="toggleAllUnits"></skif-checkbox>
                  <span class="text-darkblue font-SourceSansPro text-sm font-semibold">Показать все объекты</span>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="flex w-full items-center">
          <el-select v-if="!admin && unitOrUnitsgroup === 'unit'" v-model="unitId" :class="({ 'input-error': isUnitOrUnitGroupMissing }, 'w-2/3')" class="mt-4" filterable="filterable" type="text">
            <el-option v-for="item in currentUnits" :key="item.id" :label="item.name" :value="item.id" class="text-ellipsis" style="max-width: 272px"></el-option>
          </el-select>
          <el-select v-if="admin && unitOrUnitsgroup === 'unit'" v-model="mailing.unit.name" :class="({ 'input-error': isUnitOrUnitGroupMissing }, 'w-2/3')" class="mt-4" disabled="disabled" filterable="filterable" type="text">
            <el-option v-for="item in mailing.unit" :key="item.id" :label="item.name" :value="item.id" class="text-ellipsis" style="max-width: 272px"></el-option>
          </el-select>
        </div>
        <el-select v-if="!admin && unitOrUnitsgroup === 'group'" v-model="unitsgroupId" :class="({ 'input-error': isUnitOrUnitGroupMissing }, 'w-2/3')" class="mt-4" filterable="filterable" type="text">
          <el-option v-for="item in unitsgroups" :key="item.id" :label="item.name" :value="item.id" class="text-ellipsis" style="max-width: 272px"></el-option>
        </el-select>
        <el-select v-if="admin && unitOrUnitsgroup === 'group'" v-model="mailing.units_group.name" :class="({ 'input-error': isUnitOrUnitGroupMissing }, 'w-2/3')" class="mt-4" filterable="filterable" type="text">
          <el-option v-for="item in unitsgroups" :key="item.id" :label="item.name" :value="item.id" class="text-ellipsis" style="max-width: 272px"></el-option>
        </el-select>
        <transition name="el-zoom-in-top">
          <div v-if="isUnitOrUnitGroupMissing" class="text-notify text-xs top-full left-0 absolute">{{ unitOrUnitsgroup === 'unit' ? $t('mailing.chooseObject') : $t('mailing.chooseGroup') }}</div>
        </transition>
      </div>
      <div :class="'w-3/5'" class="flex mt-4 justify-between">
        <skif-radio v-model="unitOrUnitsgroup" label="unit" name="object" width="20">{{ $t('mailing.object') }}</skif-radio>
        <skif-radio v-model="unitOrUnitsgroup" class="ml-8" label="group" name="object" width="40">{{ $t('mailing.objectGroup') }}</skif-radio>
      </div>
      <div :class="'w-2/3'" class="flex flex-col mt-4"><span class="text-annotationColor font-SourceSansPro text-sm font-semibold">{{ $t('mailing.periodicity') }}</span>
        <div class="flex mt-4 relative">
          <skif-input-number v-model.number="mailing.periodic" :error="isPeriodicMissing" width="24"></skif-input-number>
          <el-select v-model="mailing.periodic_type" class="ml-4" filterable="filterable" type="text">
            <el-option v-for="item in periodicType" :key="item.key" :label="item.value" :value="item" class="text-ellipsis" style="max-width: 272px"></el-option>
          </el-select>
          <transition name="el-zoom-in-top">
            <div v-if="isPeriodicMissing" class="text-notify text-xs top-full left-0 absolute">{{ $t('mailing.periodicity.warning') }}</div>
          </transition>
        </div>
      </div>
      <div :class="'w-2/3'" class="flex flex-col mt-4 relative"><span class="text-annotationColor font-SourceSansPro text-sm font-semibold">{{ $t('mailing.daysweek') }}</span>
        <el-select v-model="mailing.weekdays" :class="{ 'input-error': isWeekdaysMissing }" :disabled="admin" class="mt-4" filterable="filterable" multiple="multiple" type="text">
          <el-option v-for="item in weekdaysType" :key="item.key" :label="item.value" :value="item" class="text-ellipsis" style="max-width: 272px"></el-option>
        </el-select>
        <transition name="el-zoom-in-top">
          <div v-if="isWeekdaysMissing" class="text-notify text-xs top-full left-0 absolute">{{ $t('mailing.daysweek.warning') }}</div>
        </transition>
      </div>
      <div :class="'w-2/3'" class="flex flex-col mt-4 relative"><span class="text-annotationColor font-SourceSansPro text-sm font-semibold">{{ $t('mailing.adresses') }}</span>
        <el-select v-model="mailing.delivery_emails" :class="{ 'input-error': isDeliveryEmailsMissing }" :disabled="admin" class="mt-4" filterable="filterable" multiple="multiple" type="text">
          <el-option v-for="item in users" :key="item.id" :label="item.email" :value="item.email" class="text-ellipsis" style="max-width: 272px"><span class="email-name">{{ item.name }}</span><span v-if="item.is_approved" class="email-icon">
          <EmailIcon></EmailIcon></span><span v-else="v-else" class="email-icon">
          <UnverfiedIcon :color="item.is_approved ? 'normal' : 'danger'"></UnverfiedIcon></span></el-option>
        </el-select>
        <transition name="el-zoom-in-top">
          <div v-if="isWeekdaysMissing" class="text-notify text-xs top-full left-0 absolute">{{ $t('mailing.adresses.warning') }}</div>
        </transition>
      </div>
      <div :class="'w-2/3'" class="flex flex-col mt-4 relative"><span class="text-annotationColor font-SourceSansPro text-sm font-semibold">{{ $t('mailing.subject') }}</span>
        <div class="flex flex-col relative justify-center">
          <el-input v-model="mailing.msg" :class="{ 'input-error': isMsgMissing }" class="mt-4" type="textarea"></el-input>
          <transition name="el-zoom-in-top">
            <div v-if="isMsgMissing" class="text-notify text-xs top-full left-0 absolute">{{ $t('mailing.subject.warning') }}</div>
          </transition>
        </div>
      </div>
      <div :class="'w-2/3'" class="flex flex-col mt-4"><span class="text-annotationColor font-SourceSansPro text-sm font-semibold">{{ $t('mailing.timeDateSent') }}</span>
        <div class="flex items-center pt-4 gap-2 justify-between">
          <el-date-picker v-model="mailing.last_run" :format="dateTimeFormat" :picker-options="pickerOptions" class="w-100" clearable="clearable" popper-class="reportsDatepicker" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
          <el-tooltip :content="$t('mailing.tooltip')" :offset="0" :open-delay="700" :visible-arrow="false" class="tooltip cursor-pointer" effect="dark" placement="bottom">
            <svg fill="none" height="15" width="15" xmlns="http://www.w3.org/2000/svg">
              <path clip-rule="evenodd" d="M7.5 13.636c3.577 0 6.136-2.559 6.136-6.136S11.077 1.364 7.5 1.364 1.364 3.923 1.364 7.5s2.559 6.136 6.136 6.136zM7.5 15a7.5 7.5 0 110-15 7.5 7.5 0 010 15zM4.964 5.63c.05-1.264.913-2.22 2.527-2.22 1.47 0 2.46.873 2.46 2.064 0 .863-.434 1.47-1.169 1.91-.718.422-.924.717-.924 1.29v.34H6.706L6.7 8.569c-.027-.785.312-1.286 1.075-1.742.673-.407.912-.73.912-1.303 0-.629-.495-1.09-1.257-1.09-.769 0-1.264.461-1.314 1.196H4.964zm2.326 5.96a.715.715 0 01-.729-.717c0-.406.323-.718.73-.718.417 0 .734.312.734.718a.713.713 0 01-.735.718z" fill="#2F80ED" fill-rule="evenodd"></path>
            </svg>
          </el-tooltip>
        </div>
      </div>
      <div :class="'w-2/3'" class="flex flex-col my-3"><span class="text-annotationColor font-SourceSansPro text-sm font-semibold">{{ $t('mailing.isActive') }}</span>
        <skif-switch v-model="mailing.is_active" class="mt-4"></skif-switch>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    EmailIcon: () => import('../notifications/icons/EmailIcon.vue'),
    UnverfiedIcon: () => import('../notifications/icons/UnverfiedIcon.vue')
  },
  props: {
    title: Number,
    mailing: {
      type: Object,
      default: () => {
        return {
          name: '',
          unit: {
            id: ''
          },
          units_group: {
            id: ''
          },
          report_template: {
            id: ''
          },
          periodic: '',
          periodic_type: {},
          weekdays: [],
          delivery_emails: [],
          last_run: '',
          msg: '',
          is_active: false
        }
      }
    },
    admin: Boolean
  },
  data() {
    return {
      showAllUnits: false,
      isOnlyUnits: false,
      isShowAllUnits: false,

      pickerOptions: {
        firstDayOfWeek: 1
      },
      unitOrUnitsgroup: 'unit',
      unitId: '',
      unitsgroupId: '',
      reportTemplateId: '',
      // validation
      isNameMissing: false,
      isPeriodicMissing: false,
      isWeekdaysMissing: false,
      isDeliveryEmailsMissing: false,
      isMsgMissing: false,
      isUnitOrUnitGroupMissing: false,
      dateFormat: '',
      timeFormat: '',
      dateTimeFormat: '',
      currentUnits: null,
      customUnits: []
    }
  },
  computed: {
    ...mapGetters('login', ['role', 'notRole']),
    ...mapState('users', {
      users: (state) => state.users
    }),
    ...mapState('dictionary', {
      periodicType: (state) => state.periodic_type,
      weekdaysType: (state) => state.weekdays_type
    }),
    ...mapState('reports', {
      templates: (state) => state.templates
    }),
    ...mapGetters('reports', ['defaultReportTemplate']),
    ...mapState('units', {
      units: (state) => state.defaultUnits,
      unitsgroups: (state) => state.unitsGroups
    }),
    ...mapState('login', {
      me: (state) => state.me
    })
  },
  watch: {
    'mailing.name': function(val) {
      this.isNameMissing = val === ''
    },
    'mailing.periodic': function(val) {
      this.isPeriodicMissing = val === ''
    },
    'mailing.weekdays': function(val) {
      this.isWeekdaysMissing = !val.length
    },
    'mailing.delivery_emails': function(val) {
      this.isDeliveryEmailsMissing = !val.length
    },
    'mailing.msg': function(val) {
      this.isMsgMissing = val === ''
    },
    unitId(val) {
      this.isUnitOrUnitGroupMissing = val === ''
    },
    unitsgroupId(val) {
      this.isUnitOrUnitGroupMissing = val === ''
    },
    unitOrUnitsgroup(val) {
      this.isUnitOrUnitGroupMissing = false
    }
  },
  methods: {
    toggleAllUnits(val) {
      console.log('toggle unit')
      if (this.showAllUnits) {
        this.currentUnits = this.units
        this.unitId = this.currentUnits[0].id
      } else if (this.customUnits.length) {
        this.currentUnits = this.customUnits
        this.unitId = this.customUnits[0].id
      } else {
        this.currentUnits = this.units
      }
    },
    valid() {
      let error = true
      if (this.mailing.name === '') {
        this.isNameMissing = !this.mailing.name
        error = false
      }
      if (this.mailing.periodic === '') {
        this.isPeriodicMissing = this.mailing.periodic === ''
        error = false
      }
      if (!this.mailing.weekdays.length) {
        this.isWeekdaysMissing = !this.mailing.weekdays.length
        error = false
      }
      if (!this.mailing.delivery_emails.length) {
        this.isDeliveryEmailsMissing = !this.mailing.delivery_emails.length
        error = false
      }
      if (this.mailing.msg === '') {
        this.isMsgMissing = this.mailing.msg === ''
        error = false
      }
      if (this.unitOrUnitsgroup === 'unit' && this.unitId === '') {
        this.isUnitOrUnitGroupMissing = this.unitId === ''
        error = false
      } else if (
        this.unitOrUnitsgroup === 'group' &&
        this.unitsgroupId === ''
      ) {
        this.isUnitOrUnitGroupMissing = this.unitsgroupId === ''
        error = false
      }
      return error
    },
    createOrUpdate() {
      if (this.valid()) {
        if (this.unitOrUnitsgroup === 'unit') {
          this.mailing.unit = { id: this.unitId }
          delete this.mailing.units_group
        } else {
          this.mailing.units_group = { id: this.unitsgroupId }
          delete this.mailing.unit
        }
        this.mailing.report_template.id = this.reportTemplateId
        this.title === 1
          ? this.$store
            .dispatch('mailings/CREATE', this.mailing)
            .then((_) => this.$emit('close'))
          : this.$store
            .dispatch('mailings/UPDATE', this.mailing)
            .then((_) => this.$emit('close'))
      }
    }
  },
  created() {
    if (this.title === 1) {
      this.reportTemplateId = this.defaultReportTemplate.id
      this.unitId = this.units.length ? this.units[0].id : ''
      this.unitsgroupId = this.unitsgroups.length ? this.unitsgroups[0].id : ''
      this.mailing.periodic_type = this.periodicType[0]
    } else {
      if (this.mailing.unit) {
        this.unitId = this.mailing.unit.id
        this.unitOrUnitsgroup = 'unit'
        this.unitsgroupId = this.unitsgroups.length
          ? this.unitsgroups[0].id
          : ''
      } else {
        if (this.mailing && this.mailing.units_group) {
          this.unitsgroupId = this.mailing.units_group.id
        } else {
          // Handle the case where units_group is not defined.
          // For example, set a default value or log an error.
          this.unitsgroupId = '' // or some default value
          console.log('error handler')
        }
        this.unitOrUnitsgroup = 'group'
        this.unitId = this.units.length ? this.units[0].id : ''
      }
      this.reportTemplateId = this.mailing.report_template.id
    }
    this.dateFormat = this.me.active_company.dateformat.key
    this.timeFormat = this.me.active_company.timeformat.key
    this.dateTimeFormat = `${ this.dateFormat } ${ this.timeFormat }`
    const filters = JSON.parse(
      localStorage.getItem(`filtredUnits_${ this.me.active_company.id }`)
    )

    if (filters && filters.units && filters.units.length) {
      this.customUnits = filters.units
      this.unitId = this.customUnits[0].id
      this.isOnlyUnits = false
      if (filters.units.length === this.units.length) {
        this.currentUnits = this.units
        this.isOnlyUnits = false
      } else {
        this.currentUnits = filters.units
        this.isOnlyUnits = true
      }
    } else {
      this.currentUnits = this.units
      this.isOnlyUnits = false
    }
    if (this.title === 2) {
      this.currentUnits = this.units
      this.showAllUnits = true
    }
  }
}
</script>
<style scoped>
.email-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.email-name {
  width: calc(100% - 24px);
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
}

.email-icon {
  float: right;
}

.tooltip {
  width: 34px;
}

.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>
